
import "./App.css";
import Navbar from "./components/Navbar";
import Homepage from "./components/Homepage";
import GamesPage from "./components/GamesPage";
import PlayGame from "./components/PlayGame";
import {BrowserRouter, Route, Switch} from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Navbar />
      <Switch>
        <Route exact path="/">
          <Homepage />
        </Route>
        <Route exact path="/games">
          <GamesPage />
        </Route>
        <Route path="/games/play">
          <PlayGame />
        </Route>
      </Switch>
    </div>
    </BrowserRouter>
  );
}

export default App;
