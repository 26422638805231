import React from 'react';
import '../css/GamesPage.css';
import GameCard from './GameCard';

export default function GamesPage() {
  return(
    <>
      <section className="games-grid">
        <GameCard gameName="tunnelrun" displayName="Tunnel Run" gameDescription="This is a pretty bad old game where you roll around in a tunnel and avoid obstacles." />
        
      </section>
    </>
  )
}
