import React from 'react';

export default function GameCard(props) {
  const onGameClick = (e) =>{
    window.location.href = `/games/play/?name=${props.gameName}`; // change this to id later
  }

  return(
    <div className="game-card" onClick={onGameClick}>
      <h1>{props.displayName}</h1>
      <p>{props.gameDescription}</p>
      
    </div>
  )
}
