import React from "react";
import "../css/Homepage.css";

export default function Homepage() {
  return (
    <>
      <div className="welcome-container">
        <span className="text1">Welcome</span>
        
      </div>
    </>
  );
}
//<span className="text2">welcome</span>
/*
<p>
        You thought the 19th century was amazing? Wait until you see what the
        20th has in store!
      </p>
*/
