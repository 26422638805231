import React from "react";
import "../css/PlayGame.css";

export default function PlayGame() {
  const gameName = new URLSearchParams(window.location.search).get("name");

  const url = `http://fray.charliedt.net/charlie/${gameName}`;

//<h1 className="game-isnt-loading">Game isn't loading? Click <a onClick={}>here.</a></h1>
  return (
    <>
      
      <div className="game-container">
        <h1 className="game-isnt-loading">Game isn't loading? Click <a href={url} target="_blank">here.</a></h1>
        <iframe src={url} className="game-iframe" allowFullScreen="true" title={gameName}></iframe>
        
      </div>
    </>
  );
}
