import React from "react";
import { useEffect } from "react";
import "../css/Navbar.css";

export default function Navbar() {
  useEffect(() => {
    /*window.onclick = function (event) {
      if (
        !(
          event.target.matches(".dropdown-btn") ||
          event.target.matches(".dropdown-content")
        )
      ) {
        closeAllDropdowns();
      }
    }*/
    setupDropdownBtns();
  }, []);

  const setupDropdownBtns = () => {
    const btnTools = document.getElementById("btn-tools");
    const toolsDropdown = document.getElementById("tools-dropdown");
    btnTools.addEventListener("mouseenter",(e) =>{enableDropdown("tools-dropdown")});
    toolsDropdown.addEventListener("mouseleave",(e) => {disableAllDropdowns()})
    
    const btnLinks = document.getElementById("btn-links");
    const linksDropdown = document.getElementById("links-dropdown");
    btnLinks.addEventListener("mouseenter",(e) =>{enableDropdown("links-dropdown")});
    linksDropdown.addEventListener("mouseleave",(e) => {disableAllDropdowns()})
    
  }

  const goHome = (e) => {
    window.location.href = "/";
  };

  const goToGames = (e) => {
    window.location.href = "/games";
  }

  const enableDropdown = (id) => {
    disableAllDropdowns();
    var dropDown = document.getElementById(id);
    dropDown.classList.add("show-dropdown");
  };

  const disableDropdown = (id) => {
    var dropDown = document.getElementById(id);
    dropDown.classList.remove("show-dropdown");
  }

  const toggleDropdown = (id) => {
    var toolsDropdown = document.getElementById(id);
    toolsDropdown.classList.toggle("show-dropdown");
  };

  const disableAllDropdowns = () => {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    for (let i = 0; i < dropdowns.length; i++) {
      let openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("show-dropdown")) {
        openDropdown.classList.remove("show-dropdown");
      }
    }
  };

  return (
    <div>
      <nav className="main-nav">
        <ul>
          <li>
            <span className="main-logo" onClick={goHome}>
              CDT
            </span>
          </li>
          <li>
            <button className="nav-btn btn-underline" onClick={goHome}>
              Home
            </button>
          </li>
          <li>
            <button className="nav-btn btn-underline" onClick={goToGames}>
              Games
            </button>
          </li>
          <li>
            <button
              id="btn-tools"
              className="nav-btn dropdown-btn btn-underline"
            >
              Tools ˅
            </button>
            <div id="tools-dropdown" className="dropdown-content">
              <a href="/">Nothing here yet</a>
              <br></br>
            </div>
          </li>
          <li>
            <button
              id="btn-links"
              className="nav-btn dropdown-btn btn-underline btn-links"
            >
              links ˅
            </button>
            <div id="links-dropdown" className="dropdown-content">
              <a href="https://github.com/CheesyBones" target="_blank">
                Github
              </a>
              <br></br>
              <a href="https://www.youtube.com/channel/UCaKLqH7ieXbOJE7xS4ZjGWA" target="_blank">
                YouTube
              </a>
              <br></br>
              <a href="https://www.instagram.com/charlie.thompson3/" target="_blank">
                Instagram
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}
